
h1.author-title {
	font-size: 55px;
	line-height: 53px;
	font-weight: 700;
	color: #006837;
	margin-bottom: 32px;
	margin-left: -4px;
}

.author-specs {
	font-size: 18px;

	.caption {
		&:first-of-type {
			padding-top:18px;
		}
		color:#4D4D4D;
	}


	.author-city {
		padding:rem(6) 0;
		border-top:1px solid;
		border-bottom:1px solid;
		border-color:#009245;
		text-transform: uppercase;
		font-size: 18px;
		line-height: 30px;
		letter-spacing: 1.2px;
		max-width: 80%;
		margin-bottom: 38px;
	}
	.author-genres {
		@extend %pt-sans;
		font-size:rem(16);
		text-transform: uppercase;
		line-height: 1.3;
	}

	.author-visit-type {
		margin-bottom: 24px;
	}

	img {
		margin-bottom: 18px;
	}

	a,p {
		font-size: 18px;
	}

	.printlink {
		display: block;
		margin-top: 44px;
		padding-top: 4px;
		text-decoration: underline;
		color: #006837;
		font-weight: bold;
	}
	.print-text {
		color: #606060;
		max-width: 80%;
	}

	.basic-button {
		margin-top: 28px;
	}

}

.author-info {

	@include breakpoint(small only) {
		margin-top: rem(30);
	}

	p {
		font-size: 18px;
		line-height: 30px;
	}

	.author-ingress > p {
	    color: #717071;
	    font-size: 26px;
	    line-height: 36px;
	    margin-bottom: 24px;
	}
	.author-ingress:empty {
		height:0px;
		margin:0px;
		display: block;
		overflow: hidden;
	}

	h2 {
		font-size: 30px;
		margin-bottom: 6px;
		line-height: 1;
		margin-top: 42px;
		color: #3A3A3A;
		padding-top: 16px;
	}

	h2.h2-first {
		margin-top: 0px;
		padding-top:0px;
	}

	.author-links {
		margin-top: 32px;
		margin-bottom: 32px;

		h2 {
			margin-top: 4px;
		}
		background-color: #E7F2EB;
		padding: 32px 32px;
		a {
			display: block;
			font-size: 18px;
			color: #006837;
			text-decoration: underline;
			line-height: 36px;
			&:hover, &:active, &:focus {
				color:#000;
			}
		}
	}
}

.author-methods {
	font-size:rem(13);
}