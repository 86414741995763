
$font-serif: "utopia-std", serif;
$font-sans-serif: "proxima-nova", sans-serif;

html {
    box-sizing: border-box;
    font-size: 62.5%;
}

.bookmania,
%bookmania {
    /*font-family: "bookmania", serif;*/
    font-family: $font-sans-serif;
    font-style: normal;
    font-weight: 400;
}

.bookmania-italic,
%bookmania-italic {
    /*font-family: "bookmania", serif;*/
    font-family: $font-sans-serif;
    font-style: italic;
    font-weight: 400;
}

.bookmania-bold,
%bookmania-bold {
    /* font-family: "bookmania", serif; */
    font-family: $font-sans-serif;
    font-style: normal;
    font-weight: 700;
}

.bookmania-bold-italic,
%bookmania-bold-italic {
    /* font-family: "bookmania", serif; */
    font-family: $font-sans-serif;
    font-style: italic;
    font-weight: 700;
}

.karmina,
%karmina {
    font-family: $font-sans-serif;
    font-style: normal;
    font-weight: 400;
}

.karmina-italic,
%karmina-italic {
    font-family: $font-sans-serif;
    font-style: italic;
    font-weight: 400;
}

.karmina-bold,
%karmina-bold {
    font-family: $font-sans-serif;
    font-style: normal;
    font-weight: 700;
}

.karmina-bold-italic,
%karmina-bold-italic {
    font-family: $font-sans-serif;
    font-style: italic;
    font-weight: 700;
}

.pt-sans,
%pt-sans {
    font-family: "proxima-nova", sans-serif;
    font-style: normal;
    font-weight: 400;
}

.pt-sans-bold,
%pt-sans-bold {
    font-family: "proxima-nova", sans-serif;
    font-style: normal;
    font-weight: 700;
}

.pt-sans-narrow,
%pt-sans-narrow {
    font-family: "proxima-nova", sans-serif;
    font-style: normal;
    font-weight: 400;
}
