// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

// Third-party libraries
// @import '../../bower_components/foundation-sites/scss/vendor/normalize';

// Sass utilities
@import "../../bower_components/foundation-sites/scss/util/util";

// Global variables and styles
@import "../../bower_components/foundation-sites/scss/global";

// Components
@import "../../bower_components/foundation-sites/scss/grid/grid";
@import "../../bower_components/foundation-sites/scss/typography/typography";
@import "../../bower_components/foundation-sites/scss/forms/forms";
@import "../../bower_components/foundation-sites/scss/components/visibility";
@import "../../bower_components/foundation-sites/scss/components/float";
@import "../../bower_components/foundation-sites/scss/components/button";
// @import '../../bower_components/foundation-sites/scss/components/button-group';
// @import '../../bower_components/foundation-sites/scss/components/accordion-menu';
// @import '../../bower_components/foundation-sites/scss/components/accordion';
// @import '../../bower_components/foundation-sites/scss/components/badge';
// @import '../../bower_components/foundation-sites/scss/components/breadcrumbs';
@import "../../bower_components/foundation-sites/scss/components/callout";
// @import '../../bower_components/foundation-sites/scss/components/close-button';
// @import '../../bower_components/foundation-sites/scss/components/drilldown';
// @import '../../bower_components/foundation-sites/scss/components/dropdown-menu';
// @import '../../bower_components/foundation-sites/scss/components/dropdown';
// @import '../../bower_components/foundation-sites/scss/components/flex-video';
// @import '../../bower_components/foundation-sites/scss/components/label';
// @import '../../bower_components/foundation-sites/scss/components/media-object';
// @import '../../bower_components/foundation-sites/scss/components/menu';
// @import '../../bower_components/foundation-sites/scss/components/off-canvas';
// @import '../../bower_components/foundation-sites/scss/components/orbit';
// @import '../../bower_components/foundation-sites/scss/components/pagination';
// @import '../../bower_components/foundation-sites/scss/components/progress-bar';
// @import '../../bower_components/foundation-sites/scss/components/reveal';
// @import '../../bower_components/foundation-sites/scss/components/slider';
// @import '../../bower_components/foundation-sites/scss/components/sticky';
// @import '../../bower_components/foundation-sites/scss/components/switch';
// @import '../../bower_components/foundation-sites/scss/components/table';
// @import '../../bower_components/foundation-sites/scss/components/tabs';
// @import '../../bower_components/foundation-sites/scss/components/title-bar';
// @import '../../bower_components/foundation-sites/scss/components/top-bar';
// @import '../../bower_components/foundation-sites/scss/components/thumbnail';
 @import '../../bower_components/foundation-sites/scss/components/tooltip';
$header-sizes: (
  small: (
    "h1": 24,
    "h2": 20,
    "h3": 19,
    "h4": 18,
    "h5": 17,
    "h6": 16
  ),
  medium: (
    "h1": 60,
    "h2": 40,
    "h3": 30,
    "h4": 24,
    "h5": 20,
    "h6": 16
  )
);
// @mixin foundation-everything {
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
//   @include foundation-accordion;
//   @include foundation-accordion-menu;
//   @include foundation-badge;
//   @include foundation-breadcrumbs;
//   @include foundation-button-group;
@include foundation-callout;
//   @include foundation-close-button;
//   @include foundation-drilldown-menu;
//   @include foundation-dropdown;
//   @include foundation-dropdown-menu;
//   @include foundation-flex-video;
//   @include foundation-label;
//   @include foundation-media-object;
//   @include foundation-menu;
//   @include foundation-off-canvas;
//   @include foundation-orbit;
//   @include foundation-pagination;
//   @include foundation-progress-bar;
//   @include foundation-slider;
//   @include foundation-sticky;
//   @include foundation-reveal;
//   @include foundation-switch;
//   @include foundation-table;
//   @include foundation-tabs;
//   @include foundation-thumbnail;
//   @include foundation-title-bar;
   @include foundation-tooltip;
//   @include foundation-top-bar;
// }
