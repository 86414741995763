.order-history-orders {
    .order {
        padding: rem(10);
        h4 {
            margin: 0;
        }
    }
    .order-details {
    	p {
    		margin-top: rem(20);
    	}
    	li {
    		line-height: 1.55rem;
    	}
    }
    .order:nth-child(even) {
        background: #f5f5f5;
    }
}
