.basic-button,
.button-primary {
    background: #006837;
    padding: 0.6rem 2rem 0.3rem;
    margin-right: -0.9375rem;
    border: none;
    color: #FFF;
    line-height: 1;
    @extend %bookmania-bold;
    text-align: center;
    &:hover {
        // transform:scale(1.05);
        color: #f1f1f1;
        outline: 0;
    }
    &:focus,
    &:active {
        // transform:scale(1.02);
        outline: 0;
    }
    display: inline-block;
    &.add-new-author-button {
        margin-right: 0.9375rem;
    }
}

.search-button:not(.embedded-button),
.show-more-button {
    width: 80%;
    max-width: rem(300);
    @include breakpoint(small only) {
        width: 100%;
        max-width: 100%;
        margin-left: -0.9375rem;
    }
}

.wide-button {
    width: 100%;
}

.clear-reservation-form {
    font-size: 0.625rem;
    background: #AF1414;
    height: rem(31);
}

.submit-reservation {
    position: absolute;
    right: 0.9375rem;
    top: 0px;
    height: 1.9375rem;

    &:hover {
        cursor: pointer;
    }
}

.search-toggle-button {
    background: $green;
    width: 100%;
    color: #FFF;
    padding: rem(10);
    font-weight: bold;
    margin-bottom: rem(20);
    &.search-button {
        margin-left:0;
    }
}

.letter-button {
    @extend %bookmania;
    font-size: rem(12);
    line-height: rem(12);
    color: $green;
    padding:rem(6) rem(8) rem(3);
    margin-top: rem(-10);
    &.selected {
        color:#FFF;
        background: $green;
    }
}


.disabled {
    background-color: grey;
}