.single-ktcollection {
    .site-sub-title {
        @extend %pt-sans-bold;
        font-size: rem(16);
        text-transform: uppercase;
        color:#D4E5BD;
    }
}

.author-box-kooste p {
	min-height: 230px;
}