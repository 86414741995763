$base-font-size: 16;
@function em($px, $base: $base-font-size) {
    @return ($px / $base) * 1em;
}

@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

@mixin ratio-box ( $ratio: 1/1) {
    overflow: hidden;
    position: relative;
    // The container's height, as a percentage of the
    // container's width, is set by assigning
    // padding-top to a pseudo-element.
    // @media screen and (min-width: 64rem) {
        &:before {
            content: "";
            display: block;
            height: 0;
            padding-top: (1 / $ratio) * 100%;
        }
    // }
}
%ratio-box {
    @include ratio-box;
}


/* LUKUKESKUS */


$color-green: #5AC37D;
$color-green-2: #227151;
$color-green-3: #006837;
$color-green-4: #EAF8EF;
$color-black: #4a4a4a;
$color-black-2: #3a4a4a;
$color-black-3: #606060;

$nav-height-desktop: 200px;
$nav-height-mobile: 100px;

$global-width: 100%;
