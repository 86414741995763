.results-column {
    margin-top: 8px;
}


.results-column .slider--outer--slick {
    background-color: #fff;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    margin-bottom: 42px;
}

.results-column .slider--outer--margin {
    margin-left: auto;
    margin-right: auto;
}


.results-column  .slider--outer--top {
    display: grid;
    grid-template-columns: auto auto;
    //grid-template-rows: ;
    gap: 1px 20px;
}

.results-column  .flex--first {
}

.results-column  h2.slider--outer--slick__title {
    font-size: 35px;
    line-height: 45px;
    color: #4A4A4A;
    margin-top: 35px;
    margin-left: 16px;
}

.results-column  .flex--second {
    justify-self: end;
    text-align: right;
    align-self: end;
    padding-bottom: 14px;
}

.results-column  .flex--second a {
font-size:  16px;
color: #3A3A3A;
font-weight: 700;
letter-spacing: 0.4px;
margin-right: 15px;
}

.results-column  .slider--outer--slick__text {
    color: #3A3A3A;
    font-size: 16px;
    line-height: 20px;
    margin-left: 16px;
}

.results-column  .slider--slick {
    margin-top: 20px;
}

.results-column  .slider--slick__column--margin {

}

.results-column  .slick-slide {
    margin-left: 5px;
    margin-right: 5px;
}
.results-column  .slick-slide:first-child {
    margin-left: 0px;
    margin-right: 10px;
}
.results-column  .slick-slide:nth-child(4n+0) {
    margin-left: 10px;
    margin-right: 0px;
}

.results-column  .slider--slick__column {
    background-color: #fff;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 2px;
    border: 1px solid #D8D8D8;
    min-height: 475px;
    margin-right: 5px; /*must be here that calculation works */
    margin-bottom: 30px;
}

.results-column  .slider--slick__column:hover {
    /*box-shadow: 1px 1px 18px #dddddd; */
    
    /* box-shadow: 0 20px 44px -20px rgb(53 103 83 / 62%); */ /* too big */
    box-shadow: 0 20px 24px -20px rgba(53, 103, 83, 0.52);
}

.results-column  .slider--slick__column__image-wrapper {
    flex: 1;
    flex-basis: 45%;
    padding: 0 0px;
    margin-bottom: 14px;
    position: relative;
}

.results-column  .slider--slick__column__image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
    width: 100%;
}

.results-column .slider--slick__column__text {
    padding-left: 15px;
    padding-right: 10px;
    min-height: 170px;
    padding-top: 10px;
}

.results-column  .slider--slick__column p.text {
    margin-top: 14px;
    font-size: 16px;
    line-height: 15px;
    text-align: left;
    line-height: 22px;
    color: #717071;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
}

.results-column  .writer-name {
    font-size: 25px;
    font-family: "utopia-std", serif;
    color: #4A4A4A;
    margin-bottom: 18px;
    font-weight: 700;
    line-height: 20px;
    display: block;
    clear: both;
    /*
    white-space: normal;
    word-break: break-all;
    */
}

.author-box.columns {
    margin-bottom: 32px;
}




@media (max-width: 768px) {
    .results-column  .writer-name {
        font-size: 20px;
    }
    
    h3.search-title, .search-form h3 {
        margin-left: 8px;
    }

    .row.results-row {
        /*mobile, larger */
        margin-left: -20px;
        margin-right: -20px;
    }
    .row.results-row, .wrapper .row.row.results-row, #content .wrapper .row.row.results-row {
        max-width: unset !important;
    }



    .results-column .slider--slick__column p.text {
        min-height: 198px;
        white-space: normal;
        word-break: break-all;
    }
    .results-column .writer-name {
        min-height: 42px;
    }
}


@media screen and (min-width: 320px) and (max-width: 480px) {
.results-column .slider--slick__column p.text {
    min-height: 38px!important;
}
.results-column .writer-name {
    min-height: 32px;
}

}
@media screen and (min-width: 340px) and (max-width: 640px) {
.results-column  .slider--slick__column__image {
    height: 450px;
}
}
@media screen and (min-width: 440px) and (max-width: 640px) {
.results-column  .slider--slick__column__image {
    height: 640px;
}
.results-column .slider--slick__column p.text {
    min-height: 38px!important;
}
.results-column .writer-name {
    min-height: 22px;
}
}

@media screen and (min-width: 480px) and (max-width: 968px) {
.results-column .slider--slick__column p.text {
    min-height: 138px!important;
}
.results-column .writer-name {
min-height: 62px;
}
}


@media screen and (min-width: 600px) and (max-width: 1023px) {
    .results-column .writer-name {
        min-height: 62px;
        white-space: normal;
        word-break: break-word;
    }
    .results-column  .slider--slick__column p.text { 
        min-height: 136px;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1199px) {
    .results-column .writer-name {
        min-height: 62px;
    }
    .results-column  .slider--slick__column p.text { 
        min-height: 230px;
    }
}
@media screen and (min-width: 601px) and (max-width: 1199px) {
    .results-colum .writer-name {
        min-height: 62px;
    }
    .results-colum  .writer-name { 
        margin-bottom: 0px;
    }
    .results-colum  .slider--slick__column p.text { 
        min-height: 110px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .results-column .writer-name {
        min-height: 62px;
    }
    .results-column  .slider--slick__column p.text { 
        min-height: 110px;
    }
}


