.single-post.single-twig {
	h1 {
		font-size: 55px;
	}
	h2 {
		font-size: 35px;
	}
	h3 {
		font-size: 28px;
	}
	h4 {
		font-size: 26px;
	}

	.article-body {
		font-size: 1.8rem;
	}
}