body {
    @extend %karmina;
}

/*
p,
a {
    @extend %karmina;
    font-size: rem(16);
}
*/

strong a {
    font-weight: bold;
}

a {
    color: $green;
}

a:hover,
a:focus {
    color: $green;
}

h1,
h2,
h3,
h4,
h5 {
    @extend %bookmania-bold;
    color: $green;
    position: relative;
    small {
        font-size: rem(12);
        color: inherit;
        position: absolute;
        right: 0;
        bottom: auto;
    }
}

.site-title {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-size: 4.65rem;
    width: 90%;
    text-align: center;
    left: 50%;
    &--small {
        font-size: 2rem;
        color: $green;
        position: static;
        text-align: left;
        margin-top: 2rem;
    }
    @include breakpoint(small only) {
        font-size: 1.7rem;
    }
    @include breakpoint(medium only) {
        font-size: 3.3rem;
    }
}

.border-bottom {
    border-bottom: 1px solid #B3B3B3;
    padding-bottom: rem(10);
    margin-bottom: rem(20);
    &--small {
        border-bottom: 1px solid #B3B3B3;
        padding-bottom: rem(5);
        margin-bottom: rem(10);
    }
}

.uc {
    text-transform: uppercase;
}

.page .article-body {
    max-width: 100%;
    float: none!important;
    clear: both;
}


.notification-message {
    font-size: 16px;
    margin: 20px 0px;
}