.author-pick {
    position: relative;
    padding-bottom: 24%;
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: 1.875rem;
    @include breakpoint(small only) {
        margin-bottom: rem(20);
        padding-bottom: 40%;
    }
    a {
        overflow: hidden;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
    }
    img {
        width: 100%;
        height: auto;
    }
    .pick-center {
        background-color: rgba(6, 58, 32, 0.8);
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        min-width: 70%;
        min-height: 50%;
        text-align: center;
        padding: 0;
        width: 0%;
        height: 0%;
        transition: all 750ms ease 0ms;
    }
    &:hover {
        .pick-center {
            transform: translate(-50%, -50%) scale(3);
            transition: all 750ms ease 450ms;
        }
    }
    .pick-border {
        position: absolute;
        width: 0.9375rem;
        height: 100%;
        background-color: #FFF;
        top: 0;
        &.border-left {
            left: 0;
        }
        &.border-right {
            right: 0;
        }
    }
    .pick-texts {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 70%;
        min-height: 30%;
        @include breakpoint(small only) {
            min-height: 35%;
        }
        text-align: center;
        padding: 0;
        width: 0%;
        height: 0%;
        transition: all 250ms ease;
        span {
            color: #FFF;
            &.pick-title {
                white-space: nowrap;
                @extend %bookmania-bold;
                font-size: rem(40);
                font-weight: bold;
                padding: rem(20) rem(20) 0;
                padding: 0;
                display: inline-block;
                @include breakpoint(small only) {
                    font-size: rem(20);
                    padding: rem(10) rem(20) 0;
                }
                @include breakpoint(medium only) {
                    padding: 0;
                    font-size: rem(25);
                }
            }
            &.pick-sub-title {
                @extend %pt-sans;
                color: $limeGreen;
                text-transform: uppercase;
                font-size: rem(13);
                display: inline-block;
                // padding-bottom: rem(20);
            }
        }
    }
}
