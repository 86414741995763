.page-template-page-reservation {
    .author-list-title {
        padding-bottom: 28px;
        small {
            // position: relative;
            top: 42px;
            font-size: 14px;
        }
    }
    .author-title {
        font-size: 20px;
    }
    .contents {
        padding: 20px 10px 10px;
        // border: 1px solid #cacaca;
        background: #E7F2EB;
    }
    .author-row, .add-new-author-row {
        margin-bottom: 20px;
    }
    p span.info {
        font-size: 14px;
    }
    .reservation-title, .author-list-title {
        font-size: 28px;
        margin-top: 26px;
    }
    .copy-address {
        color: $green;
    }
    .remove-author-button {
        color: red;
        @extend %pt-sans-bold;
    }
    .success-message--reservation {
        font-size: 16px;
        margin-top: 22px;
        background: #FFF;
        border-top: 2px solid $green;
    }
    .button-wrap {
        position: relative;
        .basic-button {
            @media (max-width: 1220px) {
                position: relative!important;
                clear:both;
                display: block;
                width: 100%;
                right: unset;
                margin-top: 12px;
            }
        }
    }
    small.info {
        color: $green;
        font-size: 14px;
    }
    .add-new-author-row:not(.duplo):not(.created):not(.active) .contents {
        background: #FFF;
        border: 1px solid #009245;
    }
    .add-new-author-row.active .contents {
        background: #E7F2EB;
        border: 1px solid #FFF;
    }
}

.reservation-form {
    font-size: 18px;

    label {
        display: block;
        font-size: 16px;
    }
    small {
        font-size: 14px;
    }
    input, select, textarea {
        font-size: 16px;
        font-family: $font-sans-serif;
    }
    input, select {
        height: 36px;
    }
}

.wpcf7-list-item,
.confirmations-container .checkbox-confirmation-with-link,
.form-true-false-container .radio-button-label {
    display: flex;
    input {
        height: auto;
        margin: 0;
        margin-right: 6px;
    }
    .margin-to-right {
        margin-right: 4px;
    }
    .label-with-link a {
        text-decoration: underline;
    }
}

@media (max-width: 480px) {
    .add-new-author-form .row.add-new-author-row .contents {
        margin-right: -12px;
        max-width: 100vw;
    }

}

@media (max-width: 768px) {
    h3.author-list-title {
        margin-bottom: 28px;
        &>small {
            display: block;
            clear: both;
            width: 100%;
            margin-top: 16px;
        }
    }

    .page-template-page-reservation .button-wrap button {
        display: block;
        clear: both;
        width: unset;

    }



}