/*Lukukeskus typography */
/*
* {
    font-family: $font-sans-serif;
}
*/

body {
    font-family: $font-sans-serif;
}

html > body {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

h1 {
    font-size: 3.5rem;
    color: $color-black;
    //OR #227151;
    line-height: 35px;
    font-family: $font-serif;
    font-weight: 700;
    font-style: normal;

    @media screen and (min-width: 768px) {
        font-size: 5.5rem;
        line-height: 53px;
    }
}

h2 {
    font-size: 2.5rem;
    color: $color-black;
    line-height: 30px;
    font-family: $font-serif;
    font-weight: 700;
    font-style: normal;

    @media screen and (min-width: 768px) {
        font-size: 3.2rem;
        line-height: 45px;

    }
}

.news-container > .news__title-wrapper > h2 {
  font-size: 3.5rem;
  color: $color-black;
  line-height: 35px;
  font-family: $font-serif;
  font-weight: 700;
  font-style: normal;

  @media screen and (min-width: 768px) {
      font-size: 5.5rem;
      line-height: 53px;
  }
}

h3 {

    font-size: 2rem;
    letter-spacing: 0.5px;
    line-height: 26px;
    color: $color-green-2;
    font-family: $font-serif;
    font-weight: 700;
    font-style: normal;

    @media screen and (min-width: 768px) {
        font-size: 2.3rem;
        line-height: 32px;
    }
}

h4 {
    font-family: $font-serif;
    font-weight: 700;
}

.banner__introduction, .cta__introduction {
    font-size: 2rem;
    line-height: 25px;

    @media screen and (min-width: 768px) {
        font-size: 2.2rem;
        line-height: 36px;
    }
}

p {
    font-size: 1.6rem;
    color: $color-black-2;
    letter-spacing: 0;
    line-height: 22px;

    @media screen and (min-width: 768px) {
        font-size: 1.8rem;
        line-height: 28px;
    }
}

.highlight-images--bar p {
    font-size: 2rem;
    color: #FFFFFF;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-right: 5px;

    @media screen and (min-width: 1050px) {
        font-size: 2.6rem;
    }

}

.nav-item {
    font-size: 1.3rem;
    color: $color-black-3;
    font-weight: 500;
    letter-spacing: 0.5px;
    position: relative;

    @media screen and (min-width: 925px) {
        font-size: 1.5rem;
    }
    @media screen and (min-width: 1091px) {
        font-size: 1.8rem;

    }
}

.menu-item {
  @media screen and (min-width: 768px) {
    &:hover {
        .nav-item-border {
            border-bottom-color: $color-green;
        }
    }
  }
}

.nav-item-border {
    width: 60%;
    margin: 0 auto;
    border-bottom: 3px solid transparent;
    -webkit-transition: border-bottom-color 0.5s ease;
    transition: border-bottom-color 0.5s ease;
    padding-bottom: 1rem;
}

.nav-item-out {
    font-size: 1.3rem;
    color: $color-black-3;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-decoration: none;

    &:hover {
        color: $color-green;
    }


    @media screen and (min-width: 1091px) {
        font-size: 1.4rem;

    }
}
