.one-fifth {
    width: 20%;
    @include breakpoint(small only) {
        width: 90%;
        float: none!important;
    }
}

.alert.callout.fixed {
    position: fixed;
    z-index: 99999;
    bottom: rem(-200);
    left: 0;
    width: 100%;
    margin: 0;
    transition: bottom 750ms ease;
    &.show {
        top: 0;
        height: rem(70);
        @include breakpoint(medium only) {
            height: rem(85);
        }
        @include breakpoint(small only) {
            height: rem(100);
        }
    }
    .close-button {
        position: absolute;
        color: #8a8a8a;
        right: 1rem;
        top: 0.5rem;
        font-size: 2em;
        line-height: 1;
        cursor: pointer;
    }
}
.search-form .alert.callout.fixed {
    display: none;
}
.search-form .alert.callout.fixed.show {
/* not fixed */
    display: block;
    position: relative;
    z-index: auto;
    bottom: auto;
    left: auto;
    width: 90%;
    margin: 12px auto 5px auto;
    height: auto;
    & a {
        color:#000;
        text-decoration: underline;
    }

}


@media screen and (max-width: 40em) {
    .letter-column {
        width: 10%;
        float: left;
        padding:rem(10) 0;
    }
}

@media screen and (min-width: 40em) {
    .medium-up-29 > .column:nth-of-type(1n),
    .medium-up-29 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-29 > .column,
    .medium-up-29 > .columns {
        width: 3.4%;
        float: left;
        margin-bottom: rem(10);
    }
    .medium-up-29 .column:last-child:not(:first-child) {
        float: left;
    }
}
