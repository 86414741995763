.wpcf7-form {

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      background: #EAF8EF;
      padding: 5px 5px 5px 25px;
      border: none;
      font-size: 1.6rem;
      width: 100%;
      // height: 50px;
      margin-bottom: 5px;
    }
  
    .wpcf7-response-output {
      font-size:20px;
    }
  /*
    .wpcf7-form-control-wrap.kieli {
      min-height: 30px;
      display: block;
    }
    */
    .wpcf7-form-control-wrap input {
        display: inline;
    }
    label.otherlang {
      display: block;
      margin-top: -42px;
    }
  
    .rowselect span.wpcf7-list-item {
      display: block!important;
    }
    .rowselect > span > span span.wpcf7-list-item {
      display: block!important;
    }
  
    img.form-example {
      max-width: 280px;
      display: block;
      clear: both;
      margin: 10px auto;
    }
  
    .text-center {
      text-align: center;
      margin: 0px auto;
      margin-top: -15px;
      font-size: 16px!important;
    }
  
    .attachments {
      br {
        display: none;
      }
  
      .attachment {
        font-size: 16px;
        display: block;
        width: 100%;
  
        &:not(:first-child) {
          display: none;
          margin-top: 10px;
        }
      }
    }
  
    button.button.button--plus {
      width: auto;
      text-indent: 0;
      font-size: 13px;
      line-height: 0;
    }
  
    .wpcf7-submit {
      background: #EAF8EF;
      border-radius: 23px;
      border: none;
      height: 48px;
      width: 215px;
      color: #227151;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 14px;
      overflow: hidden;
      padding: 13px 30px;
      white-space: nowrap;
      -webkit-transition: all 1.2s ease;
      transition: all 1.2s ease;
    }
  
    .wpcf7-mail-sent-ok {
      font-size: 20px;
      line-height: 22px;
    }
  
    .wpcf7-validation-errors {
      font-size: 20px;
      line-height: 22px;
      border: 2px solid #cf0000!important;
    }
  
    .wpcf7-not-valid-tip {
      color: #dc0000;
      border-left: 8px #ff0000 solid;
      padding-left: 10px;
    }
  }
  