.order {
	.order-details {
		display: none;
	}
	&.open {
		.order-details {
			display: block;
		}
	}
	h4 a {
		font-weight: bold;
	}
}

.order-info-fields {
	label {
		display: block;
		font-size: 16px;
	}
	small {
		font-size: 14px;
	}
	input, select, textarea {
		font-size: 16px;
	}
	input, select {
		height: 36px;
	}

}