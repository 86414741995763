// * {
//     background: rgba(0, 0, 0, 0.1);
//     * {
//         background: rgba(0, 0, 0, 0.1);
//         * {
//             background: rgba(0, 0, 0, 0.1);
//             * {
//                 background: rgba(0, 0, 0, 0.1);
//                 * {
//                     background: rgba(0, 0, 0, 0.1);
//                 }
//             }
//         }
//     }
// }
header {
    margin-top: 40px;
    margin-bottom: 48px;
    a {
        font-size: 16px;
        color: #606060;

    }
    .logo-link {
        background-image: url(../../assets/img/kirjailijavierailut-logo-1-02.svg);
        width: 0;
        padding-left: 264px;
        height: 42px;
        display: block;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: 255px auto;
        margin-left: -10px;
    }
    .logo-link.sv {
        background-image: url(../../assets/img/forfattarbesok-03.svg);
    }
    .nav-lang,
    .main-menu {
        a {
            @extend %pt-sans-bold;
            /*text-transform: uppercase;*/
            font-size: 16px;
            color: #606060;
            font-weight: 500;
            letter-spacing: .5px;
        }
        .pipe {
            color: #4D4D4D;
            padding: 0 0.5rem;
            font-size: 16px;
        }
    }
    .nav-lang a {
        font-weight: 400;
    }

    .main-menu.show-for-large {
        display: grid;
        grid-template-columns: auto 300px;
        gap: 1px 2px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
        font-size: 18px;
    }
    .main-menu {
        a,
        .pipe {
            color: #606060;
            font-size: 18px;
        }
        a:hover,
        a.active {
            /*color: #006837;*/
            /*color: #5ac37d; */
            color: #227151;

        }
        margin-top: rem(10);
        margin-bottom: rem(20);

        .desktop-nav {
            /*
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
            gap: 1px 20px;
            */
            width: 90%;
            min-width: 400px;
            max-width: 850px;
            display: flex;
            justify-content: space-between;
            max-width: 850px;

            a:not(.basic-button) {
                transition: color .3s ease;
                text-decoration: none;
                //position: relative; /* hover needs this */
                &:after {
                   content: "";
                   display: block;
                   border-bottom: 3px solid #a5ddc3;
                   width: 0;
                   //position: absolute;
                   -webkit-transition: border-bottom-color .5s ease;
                   transition: border-bottom-color .5s ease;
                   margin: 0 auto;
                   margin-top: 8px;
                  }

                  &:hover:after {
                    width: 60%;
                  }
            }
            @media (min-width: 1199px) {
                width: 60%;
                &.sv {
                    width: 75%;
                }
            }
        }
    .text-right.logincolumn {
        a.current-user, a.logout-link {
            /*margin-right: 28px;*/
            font-size: 16px;
            font-weight: 400;
        }
    }

    .logincolumn .basic-button  {
        width: 125px;
        display: inline-block;
        float: right;
        clear: none;
        margin-top: -11px;
        margin-left: 25px;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
    }
    /*
    .button-rounded {
        border-radius: 30px 30px 30px 30px;
    }
    .button-auto {
        margin-top: 0px;
        background-color: #E3F5EA;
        width: 200px;
        background: #EAF8EF;
        color: #006837;
        border: none;
        height: 48px;
        font-weight: 700;
        text-transform: none;
        cursor: pointer;
        font-size: 18px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
    }
    */

    .button--left-nav-link {
        margin-top: -13px;
        width: 160px;
    }
}
    .mobile-navi-toggle {
        @extend %bookmania-bold;
        color: $green;
        padding: rem(10);
        line-height: 1;
        font-size: 18px;
    }
    .mobile-navi {
        width: 100%;
        height: 100%;
        z-index: 1000;
        position: fixed;
        top: 0;
        background: #FFF;
        left: 0;
        display: none;
        &-contents {
            position: absolute;
            /*top: 50%;*/
            /*transform: translate(0%, -70%);*/
            top: 50px;
            width: 100%;
            left: 0;
            bottom: 0;
            overflow-x: scroll;
        }
        &-header {
            margin-top: rem(20);
        }
        // .mobile-navi-toggle {
        //     position: absolute;
        //     right: 20px;
        //     top: 20px;
        // }
        &.open {
            display: block;
        }
        p {
            width: 100%;
            display: block;
            margin-bottom: 0;

            a:first-of-type {
                margin-top: 23px;
            }
        }
        .pt-sans a {
            @extend %pt-sans;
            font-size: 20px;
            text-transform: none;
            line-height: 46px;
            border-bottom: 1px solid #CCC;
            display: block;
            width: 100%;
            text-align: center;
        }
        .top-margin {
            margin-top: rem(30);
        }
        .mobile-login-links {
            margin-top: 28px;
            margin-bottom: 28px;
            border: none;
            a {
                border: none;
                font-size: 16px;
            }
        }
    }
}




@media (max-width: 480px) {
 header .logo-link {
    margin-left: 15px;
    background-size: 226px auto;
 }
 header {
     margin-top: 22px;
     margin-bottom: 22px;
 }
}

@media (max-width: 330px) {
 header .logo-link {
    margin-left: 15px;
    background-size: 196px auto;
 }

}


@media (max-width: 1200px) {
 header .main-menu.show-for-large {
    grid-template-columns: auto;
 }
 .text-right.logincolumn {
    margin-top: 20px;
 }

 header .mobile-navi-header {
    margin-top: 22px;
 }


}


/* ipad custom */
@media (min-width: 1000px) and (max-width: 1024px) {
 header .main-menu.show-for-large {
    grid-template-columns: auto 295px;
 }
 header .main-menu .desktop-nav {
    width: 92%;
 }

 header .main-menu .desktop-nav.sv {
    width: 99%;
 }

 .text-right.logincolumn {
    margin-top: 20px;
 }
 header .main-menu .desktop-nav a:not(.basic-button) {
     padding-top: 20px;
 }

 header .main-menu .button--left-nav-link {
     margin-top: 6px;
     width: 160px;
 }

}

.lukukeskus-link:before {
    content: "\203A " !important;
    font-size: 20px;
    width: 30px;
    height: 30px;
    line-height: 10px;
    padding-right: 6px;

}