@media (max-width: 768px) {
    .content-width.search-page {
        padding-left: 20px;
        padding-right: 20px;
    }
    .row > .text-right.right > button.search-button {
        float:none;
        margin-left: 5px; /* fix */
        margin-right: auto;
    }

    .labels > label {
        margin-top: 8px;
        margin-bottom: 10px;
    }
    .clear-search-form {
        text-align: right;
        float: right;
        clear: both;
        margin-top:26px;
    }
}

.show-for-small-only.search-toggle-button {
    border-radius: 30px 30px 30px 30px;
    width: 100%;
    max-width: 520px;
    background: #227151;
    color: #fff;
    border: none;
    height: 48px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

.new-author, .search-freetext {
    @extend %pt-sans-narrow;
    color: #333;
}

.search-row {
    margin-top: rem(20);
    @include breakpoint(small only) {
        margin-top:0;
    }
}

.input-wrap {
    &, .search-freetext, .search-button {
        height: rem(40);
    }
    .search-freetext {
        position: absolute;
        left:0;
        top:0;
    }
}

.search-toggle-button {
    margin-top: rem(20);
}

.search-titles {

    legend {
        @extend %karmina;
        color:#606060;
        font-weight: bold;
        font-size: 18px;
    }
    label {
        @extend %pt-sans-narrow;
        color: #606060;
        line-height: 1.4;
        font-size: 16px;
        input {
            margin-bottom: 0;
        }
    }
    .labels {
        max-height: rem(444);
        overflow: auto;
    }
    @include breakpoint(small only) {
        display: none;
    }

    label > [type='checkbox'] {
        height: auto;
    }
}

.letter-button {
    font-size: 14px;
}


