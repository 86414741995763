footer {
    margin-top: rem(60);
    background: $green_new;
    font-size: 16px;
    .footer-logo {
        width: 200px;
        height: auto;
        margin-top: 8px;
    }
    a:hover {
        text-decoration: underline;
    }

    .columns {
        margin-top: 22px;
        margin-bottom: 11px;
        p,
        a,
        .follow-us-text {
            color: #fff;
            font-size: 18px;
        }
    }
    .footer--slogan > p {
        font-family: "utopia-std", serif;
        /*font-family: "proxima-nova", sans-serif;*/
        font-size: 22px;
        letter-spacing: 0.54px;
        line-height: 28px;
    }
    h1,h2,h3,h4, .follow-us-text {
        color:#ffffff;
        font-family: "proxima-nova", sans-serif;
    }

    .row-1 {
        padding-top: 10px;
    }

    .row-2 h3, .row-2 h2 {
        min-height: 33px;
        line-height: 33px;
    }
    .row-2 h3 {
        margin-bottom: 24px;
    }
    .row-2 p {
        line-height: 23px;
    }

    .row-2 a {
        font-size: 18px;
        font-weight: bold;
    }

    .row-2 a:before {
        content: "\203A " !important; /* > */
        margin-right:  6px;
        font-size: 22px;
    }
    .row-3 {
        a {
            margin-right: 20px;
        }

        a:last-of-type {
          margin-right: inherit;
        }
    }
    .some-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        a {
            margin-right: 1rem;
            -webkit-transition: all .3s ease;

            .yt-icon, .fb-icon, .ig-icon, .tw-icon {
                -webkit-transition: all .3s ease;
                transition: all .3s ease;
            }

            &:hover {
                .yt-icon, .fb-icon, .ig-icon, .tw-icon {
                    fill: #fff;
                }
            }
        }
        .follow-us-text {
            font-weight: 700;
            margin-right: 48px;
        }

    }

    .some {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        &-text {
            text-transform: uppercase;
            font-weight: 700;
            margin-right: 2rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            @media screen and (min-width: 768px) {
                margin-right: 4rem;
            }
        }
    }



@media screen and (max-width: 601px) {
    h3 {
        margin-top: 16px;
    }
    h3:empty {
        display: none;
    }
    a,p, strong {
        line-height: 33px;
    }
    .row-3 a {
        margin-top: 8px;
        margin-bottom: 8px;
        line-height: 42px;

    }
    .columns {
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

}