.search-form {
    margin-top: rem(40);
}

.input-wrap {
    position: relative;
    .embedded-button {
        position: absolute;
        right: 0.9375rem;
        top: 0;
        height: 100%;
    }
}

// .two-columns {
.text-col {
    // -webkit-columns: 2;
    // -moz-columns: 2;
    // -ms-columns: 2;
    // columns: 2;
    // margin-bottom: 30px;
    // @include breakpoint(small only) {
    //     -webkit-columns: 1;
    //     -moz-columns: 1;
    //     -ms-columns: 1;
    //     columns: 1;
    // }
    max-width: rem(850);
    float: none!important;
    clear: both;

    // margin-right: auto;

}

.border-top {
    border-top: 1px solid #979797;
}
.error-text-column {
    display: block;
    width: 100%;
    min-height: 48px;
}

h3.search-title, .search-form h3 {
    font-size: 25px;
    line-height: 38px;
    color: #606060;
    margin-bottom: 18px;
    padding-top: 18px;
    margin-left:8px;
}

.search-titles h4, .search-titles legend {
    font-size: 20px;
    line-height: 38px;
    margin-bottom: 15px;
}

.alphabet-columns {
    position: relative;
    padding-right: 90px;
}

.alphabet-holder {
    position: relative;
    padding-right: 100px;
    .embedded-button {
        position: absolute;
        right: 1.8375rem;
        top: -13px;
        width: 93px;
        height: 39px;
    }
}

.search-advanced-padding {
    padding-top: 32px;
}

#text-content > .single-container > * {
padding: 0px;
max-width: 100%;
margin: 0;
}

@include breakpoint(small only) {
    .search-titles h4 {
        margin-top: rem(20);

    }
}

.letter-column:last-child:not(:first-child) {
    float: left;
}

.text-col.highlighted {
    margin: 60px auto;
    text-align: center;
    &, p, a {
        @extend %bookmania;
        color: $green;
        font-size:rem(20);
        // text-align: center;
    }
    a {
        color:#000;
    }
    strong a {
        font-weight: bold;
    }
}

.clear-search-form {
    clear: none;
    display: inline-block;
    font-size: 15px;
    line-height: 48px;
}


.button--rounded {
    border-radius: 30px 30px 30px 30px;
    width: 250px;
    background: #227152;
    color: #fff;
    border: none;
    height: 48px;
    font-weight: 700;
    /*text-transform: uppercase;*/
    cursor: pointer;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    &:hover {
        color: #227151;
        background: #EAF8F0;
    }
    &--light {
        color: #01743E!important;
        background: #E3F5E9;
        &:hover {
            color: #ffffff!important;
            background: #227152;
        }
    }


}
.results-row {
min-height: 1000px;
}

.results-row > h2 {
margin-left: 13px;
}

.search-page .row.search-advanced-padding > * {
    padding-top: 28px;
}


.right .search-button.button--rounded {
    float:right;
    margin-left:auto;
    margin-right:10px;
    min-width: 320px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.text-center > .button--rounded {
    margin-left: auto;
    margin-right: auto;
}

.search-form .input-wrap {
    margin-top: 20px;
    margin-bottom: 40px;
}

.search-form .input-wrap .search-element.search-freetext.search-rounded {
    border-radius: 30px 30px 30px 30px;
    background: #fff;
    padding: 5px 5px 5px 25px;
    border: 1px solid #c3c3c3;
    font-size: 1.6rem;
    width: 100%;
    height: 49px;
    margin-bottom: 5px;
}


.search-form .input-wrap .search-button.button-rounded--after {
    border-radius: 0px 30px 30px 0;
    width: 150px;
    background: #227151;
    color: #fff;
    border: none;
    height: 48px;
    font-weight: 700;
    /* text-transform: uppercase; */
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 0.34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

.search-form .input-wrap .search-button.button-rounded--after:hover,
.search-form .input-wrap .search-element.search-freetext.search-rounded:hover {
    color: #227151;
    background: #EAF8F0;
}


label {
    display: block;
    font-size: 16px;
}
small {
    font-size: 14px;
}
input, select, textarea {
    font-size: 16px;
    font-family: $font-sans-serif;
}
input, select {
    height: 36px;
}

.page-template.page-template-page-registration {

}

.columns #wp-submit, .columns .registration-form-submit.basic-button {
    margin-top: 30px;
    border-radius: 30px 30px 30px 30px;
    width: 200px;
    background: #227151;
    color: #fff;
    border: none;
    height: 48px;
    font-weight: 700;
    /*text-transform: uppercase;*/
    letter-spacing: 0.34px;
    cursor: pointer;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    &:hover {
        background: #EAF8EF;
        color: #006837;
    }
}

@media screen and (max-width: 601px) {
.search-form .input-wrap .search-button.button-rounded--after {
    width: 80px;
}
}

@media print, screen and (min-width: 992px) and (max-width: 1199px) {
    .author-box.medium-3 {
        width: 33%;
    }
    .results-column .slider--slick__column p.text {
        min-height: 140px;
    }
}