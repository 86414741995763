i.icon {
    display: inline-block;
    height: 16px;
    width: 16px;
    background-size: 16px 16px;
    top: 4px;
    position: relative;
    margin-right: 4px;
    &.info-icon {
        background-image: url(../../assets/icons/269-info.svg);
    }
}
