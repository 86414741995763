body {
    background-color: #FFF;
}

.main-image {
    height: rem(300);
    background-size: cover;
    img {
        width: 100%;
        height: auto;
    }
    position: relative;
    margin-bottom:rem(30);
}
.row, .wrapper .row, #content .wrapper .row {
    max-width: 100%!important;
}

.row.border-bottom {
    padding-bottom: 20px;
    border-bottom: 2px solid #B3B3B3;
}

.author-box.columns:last-child:not(:first-child) {
    float: left;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #777;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #777;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #777;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #777;
}

:placeholder-shown {
    /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
    color: #777;
}

.page-template.page-template-page-registration input[type=checkbox] {
    display: inline-block;
    clear: none;
    margin-top: 0px;
    padding-top: 0px;
    vertical-align: bottom;
    vertical-align: middle;
    padding-top: 22px;
    top: 5px;
}



#adduser input[type=text], 
#adduser input[type=password], 
#adduser input[type=email], 
.page-template-page-registration #loginform input[type=text], 
.page-template-page-registration #loginform input[type=password], 
.page-template-page-registration input[type=text], 
.page-template-page-registration input[type=email], 
.page-template-page-registration .registration-form input[type=text],
.page-template-page-registration .registration-form input[type=password],
.page-template-page-registration .registration-form input[type=email] {
    font-size: 16px;
    height: 34px;
}


.content-wrapper.columns {
    padding-left: 0px;
    padding-right: 0px;
}



/* gobal css for content limiting */

 .content-width {
    margin-left: auto;
    margin-right: auto;
 }


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
 .content-width {
    max-width: 100%;
 }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
 .content-width {
    max-width: 100%;
 }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
 .content-width {
    max-width: 768px;
 }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1000px) and (max-width: 1024px) {
 .content-width {
    max-width: 960px;
 }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
 .content-width {
    max-width: 1170px;
 }
}

/*Extra Extra large devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
 .content-width {
    max-width: 1370px;
 }
}


/* title fix mobile */
@media (max-width: 480px) {
 h1, h1.cta__title, h2, {
    max-width: 90vw;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
 }
}
